// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Maps from "../../blocks/Maps/src/Maps";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import MultipleStepRegistrationForm2 from "../../blocks/MultipleStepRegistrationForm2/src/MultipleStepRegistrationForm2";
import MapSettings from "../../blocks/MapSettings/src/MapSettings";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import TwofactorAuthentication from "../../blocks/TwofactorAuthentication/src/TwofactorAuthentication";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import ProjectPortfolio from "../../blocks/ProjectPortfolio/src/ProjectPortfolio";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CustomForm2 from "../../blocks/CustomForm2/src/CustomForm2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import Search from "../../blocks/Search/src/Search";
import Settings2 from "../../blocks/Settings2/src/Settings2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ProductQuickview from "../../blocks/ProductQuickview/src/ProductQuickview";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Sorting from "../../blocks/sorting/src/Sorting";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Videos4 from "../../blocks/Videos4/src/Videos4";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import FileCompression from "../../blocks/FileCompression/src/FileCompression";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import SaveSearch from "../../blocks/SaveSearch/src/SaveSearch";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";



const routeMap = {
Maps:{
 component:Maps,
path:"/Maps"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
MultipleStepRegistrationForm2:{
 component:MultipleStepRegistrationForm2,
path:"/MultipleStepRegistrationForm2"},
MapSettings:{
 component:MapSettings,
path:"/MapSettings"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
TwofactorAuthentication:{
 component:TwofactorAuthentication,
path:"/TwofactorAuthentication"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
ProjectPortfolio:{
 component:ProjectPortfolio,
path:"/ProjectPortfolio"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
CustomForm2:{
 component:CustomForm2,
path:"/CustomForm2"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
Search:{
 component:Search,
path:"/Search"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ProductQuickview:{
 component:ProductQuickview,
path:"/ProductQuickview"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Videos4:{
 component:Videos4,
path:"/Videos4"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
FileCompression:{
 component:FileCompression,
path:"/FileCompression"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
SaveSearch:{
 component:SaveSearch,
path:"/SaveSearch"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;