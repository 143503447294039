"use strict";


const baseURL = "https://propertycarep1m11new-76834-ruby.b76834.dev.eastus.az.svc.builder.cafe";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
